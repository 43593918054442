import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/auth.service';
import { PageReloadService } from '@app/shared/services/page-reload.service';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PreloadService } from '@app/shared/services/preload.service';
import { PopupService } from '@app/shared/services/popup.service';
import { marker as __ } from '@biesbjerg/ngx-translate-extract-marker';
import { Popup } from '@app/shared/models/popup';
import { PayladoService } from '@app/modules/paylado/services/paylado.service';
import { Theme, ThemeName } from '@themes/models/theme';
import { ThemeService } from '@app/modules/layout/services/theme.service';
import { partner } from '@partners/partner';
import { TranslationService } from '@app/shared/services/translation.service';
import { TerminalDataService } from '@app/shared/services/terminal-data.service';
import { Subscription, of } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DEMO_URL } from '@app/modules/registration/utils/constants';
import * as Sentry from '@sentry/angular-ivy';
import {
  DEFAULT_TERMINAL_NOT_ACTIVE_ERROR,
  DEFAULT_TERMINAL_NOT_REGISTERED_ERROR,
  DEFAULT_TERMINAL_SOMETHING_WENT_WRONG_ERROR,
  ERROR_TERMINAL_INACTIVE,
  ERROR_TERMINAL_NOT_REGISTERED,
  sentryData
} from '@app/shared/utils/constants';
import { SentryService } from '@app/shared/services/sentry.service';
import { CustomerService } from '@app/modules/customer/services/customer.service';
import { DataShareService } from '@app/shared/services/data-share.service';
import { HelperService } from '@app/shared/services/helper.service';
import { ProjectConfigService } from '@app/shared/services/project-config.service';
import { DialogStreetwearComponent } from '@app/shared/components/dialog-streetwear/dialog-streetwear.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  public environment: any;
  dongleId = null;
  currentLang: string;
  initialTranslations;
  matRippleColor = 'rgb(255,255,255,30%)';
  translationsLoaded: boolean = false;
  showTabs: boolean = false;
  showDemo: boolean = false;
  wandaEnvironment: string;
  url: string = DEMO_URL;
  ecommerceEnabled: boolean = true;
  showErrorPage = false;
  errorTranslationTag: 'terminal_not_active_text' | 'terminal_not_registered_text' | 'error_something_went_wrong';
  helpPopupSetting: Popup = {
    data: {
      cssClass: 'popup__help-text',
      content: __('home_page_info_text'),
      additionalContent: __('home_page_info_build_number'),
      withTranslation: true,
      additionalContentValue: ''
    },
    width: '50%',
    height: '50%',
    panelClass: 'popup',
    autoFocus: false
  };

  // language switcher setting
  langSwitcherSetting: Popup = {
    data: {
      cssClass: 'popup__language-switcher',
      withTranslation: false
    },
    width: '60%',
    height: '50%',
    panelClass: 'popup',
    autoFocus: false
  };

  themeSwitcherEnabled = false;
  tickerEnabled = false;
  tickerEnabledCache: boolean;
  theme: Theme;
  languages: any[];
  isLanguageSwitchOpen: boolean = false;
  showStreetWear = false;

  private terminalDataSub: Subscription = null;
  private translationsSub: Subscription = null;
  private isTerminalDataSetSub: Subscription = null;
  private getQsVersionSub: Subscription = null;
  private getCasinosListSub: Subscription = null;

  qsVersion: string;

  constructor(
    public payladoService: PayladoService,
    public activatedRoute: ActivatedRoute,
    public pageReload: PageReloadService,
    public authService: AuthService,
    public preloadService: PreloadService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private popupService: PopupService,
    private themeService: ThemeService,
    private translationService: TranslationService,
    private terminalDataService: TerminalDataService,
    private sentryService: SentryService,
    private customerService: CustomerService,
    private dataShareService: DataShareService,
    private helperService: HelperService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private projectConfigService: ProjectConfigService
  ) {
    super(activatedRoute, pageReload, authService);
    const activatedRouteSnapshot = this.activatedRoute.snapshot;
    this.dongleId = activatedRouteSnapshot.queryParams?.dongleId || localStorage.getItem('dongleId') || null;
    this.environment = this.projectConfigService.getConfig();
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    // this.getQsVersionSub = this.authService.getQsVersion().subscribe(data => {
    //   this.qsVersion = data;
    //   this.helpPopupSetting.data.additionalContentValue = this.qsVersion;
    // });
    try {
      await this.translationService.setUserLanguage('de');
      await this.authService.login();
    } catch (err) {
      this.showErrorPage = true;
      const cachedTranslations = this.preloadService.getTranslationsFromCache('de');
      if (err === ERROR_TERMINAL_INACTIVE) {
        this.errorTranslationTag =
          cachedTranslations?.['terminal_not_active_text'] || DEFAULT_TERMINAL_NOT_ACTIVE_ERROR;
      } else if (err === ERROR_TERMINAL_NOT_REGISTERED) {
        this.errorTranslationTag =
          cachedTranslations?.['terminal_not_registered_text'] || DEFAULT_TERMINAL_NOT_REGISTERED_ERROR;
      } else {
        this.errorTranslationTag =
          cachedTranslations?.['error_something_went_wrong'] || DEFAULT_TERMINAL_SOMETHING_WENT_WRONG_ERROR;
      }
    }

    this.getCasinosListSub = this.customerService.getCasinosList().subscribe(res => {
      this.dataShareService.updateCasinoList(res.casinos);
      this.dataShareService.setSelectedOffer(null);
    });

    this.isTerminalDataSetSub = this.terminalDataService.isTerminalDataSet.subscribe(loaded => {
      if (loaded) {
        this.getTerminalData();
      }
    });

    this.themeSwitcherEnabled = partner.enableThemeSwitcher;

    this.themeService.getTheme().subscribe(theme => (this.theme = theme));

    this.translate.onLangChange.subscribe(data => {
      this.currentLang = data.lang;
      this.initialTranslations = this.preloadService.getTranslationsFromRequest();
    });

    const resetCredit = await this.payladoService.resetCredit().toPromise();
    if (!resetCredit) {
      console.error('error on reset the credit!');
    }

    const deactivateCashAcceptor = await this.payladoService.deactivateCashAcceptor().toPromise();
    if (!deactivateCashAcceptor) {
      console.error('error on deactivation the cash acceptor!');
    }

    const hasPayout = await this.payladoService.terminalHasPayout().toPromise();

    if (hasPayout) {
      const { Setup } = await this.payladoService.getRecyclerData().toPromise();
      if (!Setup) {
        console.error('error on getting recycler data!');
      }

      const terminalHasBillsSetup = this.checkIfTerminalHasBillsSetup(Setup);
      if (terminalHasBillsSetup) {
        const terminalHasBills = this.checkIfTerminalHasBills(Setup);
        if (terminalHasBills) {
          this.payladoService.enableCashOutButton(true);
        } else {
          this.payladoService.enableCashOutButton(false);
        }
      } else {
        this.payladoService.enableCashOutButton(false);
      }
    } else {
      console.error('error OR machine doesnt allow payout');
      this.payladoService.enableCashOutButton(false);
    }

    this.languages = this.translationService.getLanguageList();

    this.showTabs = true;
  }

  getTerminalData(): void {
    const languageList = this.translationService.getLanguageList();
    const terminalData = this.terminalDataService.getTerminalData();
    this.showStreetWear = terminalData?.streetWearEnabled;
    this.translationsLoaded = true;

    this.helpPopupSetting.data.additionalContentValue = `${this.helpPopupSetting.data.additionalContentValue} ${terminalData.environment}`;
    const defaultLangIso = languageList?.find(lang => lang?.languageId === terminalData.defaultLanguage)?.iso;
    this.setLanguage(defaultLangIso);
    this.currentLang = defaultLangIso;
    this.initialTranslations = this.preloadService.getTranslationsFromCache(defaultLangIso);

    if (terminalData.maintenanceMode) {
      this.router.navigate(['/maintenance-mode']);
    }

    if (terminalData.tickerEnabled !== null) {
      this.tickerEnabledCache = terminalData.tickerEnabled;
      this.tickerEnabled = this.tickerEnabledCache;
    }

    this.wandaEnvironment = terminalData.environment;

    if (terminalData.ecommerceEnabled !== undefined) {
      this.ecommerceEnabled = terminalData.ecommerceEnabled;
    }

    this.themeService.setTheme(terminalData.theme ? (terminalData.theme as ThemeName) : ('default' as ThemeName));
  }

  checkIfTerminalHasBillsSetup(data: any): boolean {
    let hasBillsSetup = false;
    data.forEach(cashType => {
      if (cashType.FloatLevel !== 0) {
        hasBillsSetup = true;
      }
    });
    return hasBillsSetup;
  }

  checkIfTerminalHasBills(data: any): boolean {
    let hasBills = false;
    data.forEach(cashType => {
      if (cashType.Level !== 0) {
        hasBills = true;
      }
    });
    return hasBills;
  }

  openSwitcherLangPopup(): void {
    this.popupService.openLanguageSwitcherDialog(this.langSwitcherSetting);
  }

  openHelpPopup(): void {
    this.popupService.openDialog(this.helpPopupSetting);
  }

  setLanguage(languageIso: string): void {
    this.translate.use(languageIso);
    this.translationService.setUserLanguage(languageIso);
    this.isLanguageSwitchOpen = false;
  }

  onPolarClick(event: Event): void {
    event.stopPropagation();
    if (this.ecommerceEnabled === false) {
      return;
    }
    this.helperService.goToEcommerceAfterLogin(true);
    this.router.navigate(['/app/customer/logged-in-page']);
  }

  getImageUrl(componentName: string): string {
    let imageUrl: string;
    let partnerServiceSub: Subscription = null;
    partnerServiceSub = this.themeService
      .getImageSourceUrlByComponentName(componentName)
      .subscribe(url => (imageUrl = url));
    partnerServiceSub.unsubscribe();
    return imageUrl;
  }

  onStreetWearClick(event: Event): void {
    event.stopPropagation();
    const terminalData = this.terminalDataService.getTerminalData();
    // TODO change url when back is implemnted
    const qrCodeUri = `${this.environment.qrCodeUrl}/${this.environment.streetWearQrCode}/${terminalData.id}`;
    const streetWearDialogConfig = {
      data: {
        qrCodeUri: qrCodeUri
      },
      width: '60%',
      height: '60%',
      panelClass: 'popup',
      autoFocus: false
    };

    this.dialog.open(DialogStreetwearComponent, streetWearDialogConfig);
  }

  ngOnDestroy(): void {
    if (this.terminalDataSub) {
      this.terminalDataSub.unsubscribe();
    }

    if (this.translationsSub) {
      this.translationsSub.unsubscribe();
    }

    if (this.isTerminalDataSetSub) {
      this.isTerminalDataSetSub.unsubscribe();
    }

    if (this.getQsVersionSub) {
      this.getQsVersionSub.unsubscribe();
    }

    if (this.getCasinosListSub) {
      this.getCasinosListSub.unsubscribe();
    }
  }
}
