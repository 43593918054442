import { Injectable, Renderer2 } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { partner } from '@partners/partner';
import { ThemeName, ThemeMap, Theme } from '@themes/models/theme';

const LOCAL_STORAGE_THEME_KEY = 'gs_theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeBehaviorSubject: BehaviorSubject<Theme>;
  private themeObservable: Observable<Theme>;
  private themeSwitchingEnabled = false;

  theme: Theme = ThemeMap.get('default' as ThemeName);

  constructor() {
    //const storedThemeName: string = localStorage.getItem('theme') || 'default';
    /*  if (storedThemeName !== null) {
      if (Object.values(ThemeName).includes(storedThemeName as ThemeName)) {
        const themeName: ThemeName = <ThemeName>storedThemeName;
        if (ThemeMap.has(themeName)) {
          theme = ThemeMap.get(themeName);
        } else {
          theme = ThemeMap.get('default' as ThemeName);
        }
      } else {
        theme = ThemeMap.get('default' as ThemeName);
      }
    } */

    this.themeBehaviorSubject = new BehaviorSubject<Theme>(this.theme);
    this.themeObservable = this.themeBehaviorSubject.asObservable();
  }

  getTheme(): Observable<Theme> {
    return this.themeObservable;
  }

  setTheme(themeName: ThemeName): void {
    const theme: Theme = ThemeMap.get(themeName);
    localStorage.setItem('theme', <string>themeName);
    this.theme = theme;
    this.themeBehaviorSubject.next(theme);
    if (this.theme.cssVariables) {
      if (this.theme.cssVariables.other) {
        Object.keys(theme.cssVariables.other).forEach(key => {
          this.setCSSVariable(key, theme.cssVariables.other[key]);
        });
      }
      if (this.theme.cssVariables.urls) {
        Object.keys(theme.cssVariables.urls).forEach(key => {
          this.setCSSVariable(key, 'url(' + theme.cssVariables.urls[key] + ')');
        });
      }
    }
  }

  setCSSVariable(variable: string, value: string): void {
    document.documentElement.style.setProperty(variable, value);
  }

  getThemeTranslationTags(): { [key: string]: string } {
    let translationTags: { [key: string]: string };
    this.getTheme().subscribe(theme => {
      translationTags = theme.translationTags;
    });
    return translationTags;
  }

  getImageSourceUrlByComponentName(componentName: string): Observable<string> {
    return of(this.theme[componentName]);
  }
}
